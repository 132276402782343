import {
    AnnotationType,
    AnswerEntityId,
    CalendarDate,
    CommunityId,
    genEntityId,
    IAnswer,
    IBridgeFieldMetadata,
    ICommunity,
    InteractionType,
    IPrompt,
    IQuestion,
    LayoutSectionKey,
    NoteId,
    PromptId,
    QuestionId,
    QuestionSource,
    QuestionType,
    SectionRenderBehaviorType,
    SelectionAnnotation,
    UserId,
} from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { CommunityContacts } from "components/communities/show-community/community-contacts";
import { CommunityDetailsFields } from "components/communities/show-community/community-details-fields";
import { Header } from "components/header";
import { Notes } from "components/notes";
import { EntityPhotos } from "components/photos/entity-photos";
import { DateInput } from "components/shared/date";
import { InputCurrency } from "components/shared/input-currency";
import { Radio } from "components/shared/radio";
import { UserSelect } from "components/shared/user-select";
import { AutosavingInput } from "components/ui/autosaving-input";
import { CalendarDateNoYearInput } from "components/ui/calendar-date-no-year";
import { Checkbox } from "components/ui/checkbox";
import { Select } from "components/ui/select";
import { UrlButton } from "components/ui/url-button";
import {
    AdvancedSearchEntityType,
    AnswerInput,
    BridgeEntityResult,
    GetLayoutSectionsQuery,
    useGetEntitiesQuery,
    useSetEntityAnswerMutation,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useMemo } from "react";
import Multiselect from "react-widgets/Multiselect";
import { useBridgeQuestionsForCurrentRegion } from "store/selectors/bridge";
import { stableQueryOptions } from "util/requests";
import { LayoutItemResult, LayoutSectionResult } from "./layout-items";

interface QuestionFormItemProps {
    title: string;
    question: IQuestion;
    answer: IAnswer | null;
    onCommit: (answer: IAnswer) => void;
}

function noneValue(questionId: QuestionId) {
    return `${questionId}-na`;
}

const QuestionFormItemNarySingle: React.FC<QuestionFormItemProps> = props => {
    const { title, question, answer, onCommit } = props;

    const selection = answer?.selections?.[0] || noneValue(question.id);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const selections =
                e.target.value === noneValue(question.id)
                    ? []
                    : [e.target.value as PromptId];

            onCommit({
                ...answer,
                selections: selections,
            });
        },
        [question, answer, onCommit],
    );

    return (
        <Select label={title} value={selection} onChange={handleChange} className="mt-1">
            <option value={noneValue(question.id)}>-----</option>
            {(question.prompts || []).map(p => (
                <option key={p.id} value={p.id}>
                    {p.title}
                </option>
            ))}
        </Select>
    );
};

const QuestionFormItemNarySingleRadio: React.FC<QuestionFormItemProps> = props => {
    const { title, question, answer, onCommit } = props;

    const handleChange = useCallback(
        (value: string) => {
            const selections =
                value === noneValue(question.id) ? [] : [value as PromptId];

            onCommit({
                ...answer,
                selections: selections,
            });
        },
        [question, answer, onCommit],
    );

    let options = [{ key: noneValue(question.id), text: "-----" }];

    options = options.concat(
        (question.prompts || []).map(p => ({
            key: p.id,
            text: p.title,
        })),
    );

    const selection = answer?.selections?.[0] || noneValue(question.id);

    return (
        <div className="mt-1">
            <Radio
                options={options}
                label={title}
                value={selection}
                onChange={handleChange}
            />
        </div>
    );
};

interface QuestionFormItemNaryMultipleListItemProps {
    prompt: IPrompt;
    question: IQuestion;
    answer: IAnswer | null;
    onCommit: (answer: IAnswer) => void;
}

const QuestionFormItemNaryMultipleListItem: React.FC<
    QuestionFormItemNaryMultipleListItemProps
> = props => {
    const { prompt, question, answer, onCommit } = props;
    const isChecked = answer?.selections?.includes(prompt.id) || false;
    const annotation =
        answer?.selectionAnnotations?.find(a => a.selectionId === prompt.id)?.text || "";

    const handleCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked;
            const value = e.target.value as PromptId;

            const selections = answer?.selections.filter(s => s !== value) || [];
            if (checked) {
                selections.push(value);
            }

            onCommit({
                ...answer,
                selections: selections,
            });
        },
        [answer, onCommit],
    );

    const handleAnnotationChange = useCallback(
        (value: string) => {
            const oldAnnotations = answer?.selectionAnnotations || [];
            const selectionAnnotations = produce(oldAnnotations, draft => {
                const existing = draft.find(a => a.selectionId === prompt.id);
                if (existing) {
                    existing.text = value;
                } else {
                    draft.push(
                        SelectionAnnotation.load({
                            selectionId: prompt.id,
                            text: value,
                        }),
                    );
                }
            });

            onCommit({
                ...answer,
                selectionAnnotations: selectionAnnotations,
            });
        },
        [prompt, answer, onCommit],
    );

    return (
        <li>
            <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                label={prompt.title}
                value={prompt.id}
            />
            {question.annotation === AnnotationType.perAnswer &&
            prompt.annotation &&
            isChecked ? (
                <AutosavingInput
                    initial={annotation}
                    multiLine={true}
                    onCommit={handleAnnotationChange}
                />
            ) : null}
        </li>
    );
};

const QuestionFormItemNaryMultiple: React.FC<QuestionFormItemProps> = props => {
    const { title, question, answer, onCommit } = props;

    return (
        <div className="mt-1">
            <label className="mt-1">{title}</label>
            <ul className="space-y-0.5 pt-1">
                {(question.prompts || []).map(prompt => {
                    return (
                        <QuestionFormItemNaryMultipleListItem
                            key={prompt.id}
                            prompt={prompt}
                            question={question}
                            answer={answer}
                            onCommit={onCommit}
                        />
                    );
                })}
            </ul>
        </div>
    );
};

const QuestionFormItemNaryMultipleDropdown: React.FC<QuestionFormItemProps> = props => {
    const { title, question, answer, onCommit } = props;

    const handleChange = useCallback(
        (values: { value: string; text: string }[]) => {
            const selections = values.map(v => v.value as PromptId);

            onCommit({
                ...answer,
                selections: selections,
            });
        },
        [answer, onCommit],
    );

    const options = (question.prompts || []).map(p => ({
        value: p.id,
        text: p.title,
    }));

    return (
        <div>
            <label className="mt-1">{title}</label>
            <Multiselect
                className="mt-1"
                data={options}
                value={answer?.selections || []}
                dataKey="value"
                textField="text"
                onChange={handleChange}
            />
        </div>
    );
};

const QuestionFormItemNary: React.FC<QuestionFormItemProps> = props => {
    const { question } = props;

    switch (question.interaction) {
        case InteractionType.singleRadio:
            return <QuestionFormItemNarySingleRadio {...props} />;
        case InteractionType.single:
            return <QuestionFormItemNarySingle {...props} />;
        case InteractionType.multipleDropdown:
            return <QuestionFormItemNaryMultipleDropdown {...props} />;
        case InteractionType.multiple:
            return <QuestionFormItemNaryMultiple {...props} />;
        default:
            console.warn(`Unexpected interaction type ${question.interaction}`);
            return null;
    }
};

interface SingleAnnotationProps {
    title: string;
    question: IQuestion;
    answer: IAnswer | null;
    onCommit: (answer: IAnswer) => void;
}

const SingleAnnotation: React.FC<SingleAnnotationProps> = props => {
    const { title, question, answer, onCommit } = props;

    const handleChange = useCallback(
        (text: string) => {
            onCommit({
                ...answer,
                textAnswer: text,
            });
        },
        [answer, onCommit],
    );

    if (
        question.annotation !== AnnotationType.single ||
        (!answer?.booleanAnswer && (answer?.selections || []).length <= 0)
    ) {
        return null;
    }

    return (
        <div>
            <AutosavingInput
                multiLine={true}
                onCommit={handleChange}
                initial={props.answer.textAnswer}
                label={title + " (Notes)"}
            />
        </div>
    );
};

function defaultAnswer(questionId: QuestionId): Partial<IAnswer> {
    return {
        questionId,
        booleanAnswer: null,
        textAnswer: null,
        numberAnswer: null,
        numberAnswerHigh: null,
        dateAnswer: null,
        selections: [],
        selectionAnnotations: [],
        idReferenceAnswer: null,
    };
}

const QuestionFormItem: React.FC<QuestionFormItemProps> = props => {
    const { title, question, answer, onCommit } = props;

    const handleTextCommit = useCallback(
        (text: string) => {
            onCommit({
                ...defaultAnswer(question.id),
                ...answer,
                textAnswer: text,
                numberAnswer: null,
            });
        },
        [question, answer, onCommit],
    );

    const handleNaryCommit = useCallback(
        (answer: IAnswer) => {
            onCommit({
                ...defaultAnswer(question.id),
                ...answer,
            });
        },
        [question, onCommit],
    );

    const handleBooleanChange = useCallback(
        (value: React.ChangeEvent<HTMLInputElement>) => {
            onCommit({
                ...defaultAnswer(question.id),
                ...answer,
                booleanAnswer: value.target.checked,
            });
        },
        [question, answer, onCommit],
    );

    const handleDateChange = useCallback(
        (date: Date) => {
            onCommit({
                ...defaultAnswer(question.id),
                ...answer,
                dateAnswer: date,
            });
        },
        [question, answer, onCommit],
    );

    const handleCalendarDateChange = useCallback(
        (calendarDate: CalendarDate | null) => {
            const storableValue = calendarDate ? calendarDate.toString() : null;
            onCommit({
                ...defaultAnswer(question.id),
                ...answer,
                calendarDateAnswer: storableValue,
            });
        },
        [question, answer, onCommit],
    );

    const handleNumberChange = useCallback(
        (value: string) => {
            const numberAnswer = parseInt(value, 10);
            onCommit({
                ...defaultAnswer(question.id),
                ...answer,
                numberAnswer: isNaN(numberAnswer) ? null : numberAnswer,
            });
        },
        [question, answer, onCommit],
    );

    const handleFloatChange = useCallback(
        (value: string) => {
            const numberAnswer = parseFloat(value);
            onCommit({
                ...defaultAnswer(question.id),
                ...answer,
                numberAnswer: isNaN(numberAnswer) ? null : numberAnswer,
            });
        },
        [question, answer, onCommit],
    );

    const handleNumberHighChange = useCallback(
        (value: string) => {
            const numberAnswer = parseInt(value, 10);
            onCommit({
                ...defaultAnswer(question.id),
                ...answer,
                numberAnswerHigh: isNaN(numberAnswer) ? null : numberAnswer,
            });
        },
        [question, answer, onCommit],
    );

    const handleUserChange = useCallback(
        (value: UserId | null) => {
            onCommit({
                ...defaultAnswer(question.id),
                ...answer,
                idReferenceAnswer: value || null,
            });
        },
        [question, answer, onCommit],
    );

    switch (question.questionType) {
        case QuestionType.text:
            return (
                <div
                    className={
                        question.interaction === InteractionType.url
                            ? `flex items-end space-x-1`
                            : ""
                    }>
                    <AutosavingInput
                        label={title}
                        multiLine={question.interaction === InteractionType.multiline}
                        onCommit={handleTextCommit}
                        initial={answer?.textAnswer || ""}
                    />
                    {question.interaction === InteractionType.url ? (
                        <UrlButton url={answer?.textAnswer || ""} />
                    ) : null}
                </div>
            );
        case QuestionType.nary:
            return (
                <>
                    <QuestionFormItemNary
                        title={title}
                        question={question}
                        answer={answer}
                        onCommit={handleNaryCommit}
                    />
                    <SingleAnnotation
                        title={title}
                        question={question}
                        answer={answer}
                        onCommit={onCommit}
                    />
                </>
            );
        case QuestionType.binary:
            return (
                <>
                    <Checkbox
                        label={title}
                        checked={answer?.booleanAnswer || false}
                        onChange={handleBooleanChange}
                    />
                    <SingleAnnotation
                        title={title}
                        question={question}
                        answer={answer}
                        onCommit={onCommit}
                    />
                </>
            );
        case QuestionType.date:
            return (
                <DateInput
                    label={title}
                    onCommit={handleDateChange}
                    initial={answer?.dateAnswer || null}
                    className="small-input-field"
                />
            );
        case QuestionType.calendarDateNoYear:
            return (
                <CalendarDateNoYearInput
                    label={title}
                    onCommit={handleCalendarDateChange}
                    initial={
                        answer && answer.calendarDateAnswer
                            ? CalendarDate.parse(answer.calendarDateAnswer).getOrElse(
                                  null,
                              )
                            : null
                    }
                />
            );
        case QuestionType.number:
            return (
                <AutosavingInput
                    onCommit={handleNumberChange}
                    initial={`${answer?.numberAnswer || ""}`}
                    label={title}
                    type="number"
                />
            );
        case QuestionType.currency:
            return (
                <InputCurrency
                    initial={answer?.numberAnswer || ""}
                    onCommit={handleFloatChange}
                    label={title}
                />
            );
        case QuestionType.user:
            return (
                <UserSelect
                    includeEveryone={false}
                    includeUnassigned={true}
                    value={answer?.idReferenceAnswer || ""}
                    onChange={handleUserChange}
                    label={title}
                    unassignedLabel="Unselected"
                />
            );
        case QuestionType.range:
            switch (question.interaction) {
                case InteractionType.rangePrice:
                case InteractionType.currency:
                    return (
                        <div className="flex space-x-2 mt-1">
                            <div className="flex-1">
                                <InputCurrency
                                    initial={answer?.numberAnswer || ""}
                                    onCommit={handleNumberChange}
                                    label={`${title} (low)`}
                                />
                            </div>
                            <p className="pt-6 px-4">
                                <p className="pt-2">&#8212;</p>
                            </p>
                            <div className="flex-1">
                                <InputCurrency
                                    initial={answer?.numberAnswerHigh || ""}
                                    onCommit={handleNumberHighChange}
                                    label={`${title} (high)`}
                                />
                            </div>
                        </div>
                    );
                case InteractionType.rangeNumber:
                case InteractionType.number:
                    return (
                        <div className="flex space-x-2 mt-1">
                            <div className="flex-1">
                                <AutosavingInput
                                    initial={`${answer?.numberAnswer || ""}`}
                                    onCommit={handleNumberChange}
                                    label={`${title} (low)`}
                                    type="number"
                                />
                            </div>
                            <p className="pt-6 px-4">
                                <p className="pt-2">&#8212;</p>
                            </p>
                            <div className="flex-1">
                                <AutosavingInput
                                    initial={`${answer?.numberAnswerHigh || ""}`}
                                    onCommit={handleNumberHighChange}
                                    label={`${title} (high)`}
                                    type="number"
                                />
                            </div>
                        </div>
                    );
                default:
                    console.warn(
                        `Unexpected interaction type ${question.interaction} on range question`,
                    );
                    return null;
            }
        default:
            console.warn(`Question type ${question.questionType} not yet supported`);
            return null;
    }
};

interface FieldFormItemProps {
    title: string;
    field: IBridgeFieldMetadata;
    entity: unknown;
    onCommit: (fieldName: string, value: unknown) => void;
}

function getTextValue(entity: unknown, fieldName: string): string {
    if (typeof entity === "object" && entity) {
        const record = entity as Record<string, unknown>;
        if (typeof record[fieldName] === "string") {
            const value = record[fieldName] as string;
            return value || "";
        }
    }

    return "";
}

const FieldFormTextItem: React.FC<FieldFormItemProps> = props => {
    const { title, field, entity, onCommit } = props;

    const initial = getTextValue(entity, field.name);

    return (
        <AutosavingInput
            label={title}
            onCommit={v => onCommit(field.name, v)}
            initial={initial}
        />
    );
};

const FieldFormNaryItem: React.FC<FieldFormItemProps> = props => {
    const { title, field, entity, onCommit } = props;

    const noneSentinel = "__none__";

    const value = getTextValue(entity, field.name) || noneSentinel;

    return (
        <Select
            label={title}
            value={value}
            onChange={e => {
                const value = e.target.value;
                if (value === noneSentinel) {
                    onCommit(field.name, null);
                } else {
                    onCommit(field.name, value);
                }
            }}>
            <option value={noneSentinel}>-----</option>
            {(field.naryValues || []).map(naryValue => (
                <option key={naryValue.value} value={naryValue.value}>
                    {naryValue.label}
                </option>
            ))}
        </Select>
    );
};

function getDateValue(entity: unknown, fieldName: string): Date {
    if (typeof entity === "object" && entity) {
        const record = entity as Record<string, unknown>;
        if (typeof record[fieldName] === "string") {
            const value = record[fieldName] as string;
            return new Date(value);
        }
        if (
            typeof record[fieldName] === "object" &&
            record[fieldName] &&
            record[fieldName] instanceof Date
        ) {
            const value = record[fieldName] as Date;
            return value;
        }
    }

    return null;
}

const FieldFormDateItem: React.FC<FieldFormItemProps> = props => {
    const { title, field, entity, onCommit } = props;

    const value = getDateValue(entity, field.name) || null;

    return (
        <DateInput
            label={title}
            onCommit={d => onCommit(field.name, d)}
            initial={value}
            className="small-input-field"
        />
    );
};

function getBooleanValue(entity: unknown, fieldName: string): boolean {
    if (typeof entity === "object" && entity) {
        const record = entity as Record<string, unknown>;
        return !!record[fieldName];
    }

    return false;
}

const FieldFormBooleanItem: React.FC<FieldFormItemProps> = props => {
    const { title, field, entity, onCommit } = props;

    const value = getBooleanValue(entity, field.name);

    return (
        <Checkbox
            label={title}
            checked={value}
            onChange={e => onCommit(field.name, e.target.checked)}
        />
    );
};

function getNotesValue(
    entity: unknown,
    fieldName: string,
): { text: string; _id: NoteId }[] {
    if (typeof entity === "object" && entity) {
        const record = entity as Record<string, unknown>;
        if (Array.isArray(record[fieldName])) {
            const value = record[fieldName] as { text: string; _id: NoteId }[];
            return value;
        }
    }

    return [];
}

const FieldFormNotesItem: React.FC<FieldFormItemProps> = props => {
    const { title, field, entity, onCommit } = props;
    const noteId = useMemo(() => genEntityId<NoteId>(), []);

    const notes = getNotesValue(entity, field.name);

    const initial = notes.find(n => n._id === noteId)?.text || "";

    return (
        <AutosavingInput
            label={title}
            onCommit={v => {
                const entityNotes = getNotesValue(entity, field.name);
                const newNotes = produce(entityNotes, draft => {
                    const existingNote = draft.find(n => n._id === noteId);
                    if (existingNote) {
                        existingNote.text = v;
                    } else {
                        draft.push({
                            text: v,
                            _id: noteId,
                        });
                    }
                });

                onCommit(field.name, newNotes);
            }}
            initial={initial}
            multiLine={true}
        />
    );
};

export const FieldFormItem: React.FC<FieldFormItemProps> = props => {
    const { field } = props;

    if (field.questionType === QuestionType.text) {
        return <FieldFormTextItem {...props} />;
    }

    if (field.questionType === QuestionType.nary) {
        return <FieldFormNaryItem {...props} />;
    }

    if (field.questionType === QuestionType.date) {
        return <FieldFormDateItem {...props} />;
    }

    if (field.questionType === QuestionType.binary) {
        return <FieldFormBooleanItem {...props} />;
    }

    if (field.questionType === QuestionType.notes) {
        return <FieldFormNotesItem {...props} />;
    }

    console.warn(`Field type ${field.questionType} not yet supported`);
    return null;
};

interface LayoutFormItemProps {
    layoutItem: LayoutItemResult;
    entity: unknown;
    fields: IBridgeFieldMetadata[];
    questions: IQuestion[];
    onCommitAnswer: (answer: IAnswer) => void;
    onCommitField?: (fieldName: string, value: unknown) => void;
}

export function findAnswer(entity: unknown, question: IQuestion): IAnswer | null {
    if (typeof entity !== "object" || entity === null) {
        return null;
    }

    const obj = entity as Record<string, unknown>;

    if (!Array.isArray(obj.answers)) {
        return null;
    }

    return obj.answers.find(a => a.questionId === question.id) || null;
}

export const LayoutFormItem: React.FC<LayoutFormItemProps> = props => {
    const { layoutItem, entity, questions, fields, onCommitAnswer, onCommitField } =
        props;

    if (layoutItem.entityFieldName) {
        if (!onCommitField) {
            console.warn(`Unexpected: No onCommitField handler provided.`);
            return null;
        }

        const field = fields.find(
            f =>
                f.name === layoutItem.entityFieldName ||
                f.aliases?.includes(layoutItem.entityFieldName),
        );

        if (!field) {
            console.warn(
                `Unexpected: Field ${layoutItem.entityFieldName} not found on entity.`,
            );
            return null;
        }

        return (
            <FieldFormItem
                title={layoutItem.customTitle || field.title}
                field={field}
                entity={entity}
                onCommit={onCommitField}
            />
        );
    }

    if (layoutItem.questionId) {
        const question = questions.find(q => q.id === layoutItem.questionId);

        if (!question) {
            console.warn(
                `Unexpected: Question ${layoutItem.questionId} not found on entity.`,
            );
            return null;
        }

        const answer = findAnswer(entity, question);

        return (
            <QuestionFormItem
                title={layoutItem.customTitle || question.title}
                question={question}
                answer={answer}
                onCommit={onCommitAnswer}
            />
        );
    }

    console.warn(`Unexpected: Layout item ${layoutItem.id} has no question or field.`);
    return null;
};

type LayoutFormSectionMode = "tile" | "fieldsonly";

interface EntityLayoutFormSectionProps {
    entityId: AnswerEntityId;
    entityMetadata: BridgeEntityResult;
    entity: unknown;
    section: GetLayoutSectionsQuery["getLayoutSections"]["layoutSections"][number];
    mode: LayoutFormSectionMode;
    answerMode: QuestionSource;
    onAnswerCommitted: (entityId: AnswerEntityId, answer: IAnswer) => void;
}

function dateAnswerToString(answer: IAnswer): string | null {
    if (!answer?.dateAnswer) {
        return null;
    }

    if (typeof answer.dateAnswer === "string") {
        return answer.dateAnswer;
    }

    return answer.dateAnswer.toISOString();
}

const EntityLayoutItemsFormSection: React.FC<EntityLayoutFormSectionProps> = props => {
    const {
        entityMetadata,
        entity,
        section,
        mode,
        entityId,
        answerMode,
        onAnswerCommitted,
    } = props;

    const setEntityAnswerMutation = useSetEntityAnswerMutation();

    const handleCommitAnswer = useCallback(
        async (answer: IAnswer) => {
            if (answerMode === QuestionSource.Entity) {
                const answerInput: AnswerInput = {
                    questionId: answer.questionId,
                    booleanAnswer: answer.booleanAnswer,
                    numberAnswer: answer.numberAnswer,
                    numberAnswerHigh: answer.numberAnswerHigh,
                    selectionAnnotations: answer.selectionAnnotations,
                    selections: answer.selections,
                    dateAnswer: dateAnswerToString(answer),
                    textAnswer: answer.textAnswer,
                    idReferenceAnswer: answer.idReferenceAnswer,
                    calendarDateAnswer: answer.calendarDateAnswer as unknown as string,
                };

                await setEntityAnswerMutation.mutateAsync({
                    entityId,
                    answer: answerInput,
                });
            }

            onAnswerCommitted(entityId, answer);
        },
        [answerMode, entityId, setEntityAnswerMutation, onAnswerCommitted],
    );

    const questions = useBridgeQuestionsForCurrentRegion(
        entityMetadata.name as AdvancedSearchEntityType,
    );

    if (section.layoutItems.length <= 0) {
        return null;
    }

    return mode === "fieldsonly" ? (
        <>
            {section.layoutItems.map(layoutItem => (
                <LayoutFormItem
                    key={layoutItem.id}
                    layoutItem={layoutItem}
                    entity={entity}
                    fields={entityMetadata.fields}
                    questions={questions}
                    onCommitAnswer={handleCommitAnswer}
                />
            ))}
        </>
    ) : (
        <div className="input-form-block">
            <Header iconName={section.icon}>{section.title}</Header>
            {section.layoutItems.map(layoutItem => (
                <LayoutFormItem
                    key={layoutItem.id}
                    layoutItem={layoutItem}
                    entity={entity}
                    fields={entityMetadata.fields}
                    questions={questions}
                    onCommitAnswer={handleCommitAnswer}
                />
            ))}
        </div>
    );
};

interface LayoutFormSectionProps {
    entity: unknown;
    entityType: string;
    layoutSection: LayoutSectionResult | null;
    mode: LayoutFormSectionMode;
    entityId: AnswerEntityId;
    answerMode: QuestionSource;
    onAnswerCommitted: (entityId: AnswerEntityId, answer: IAnswer) => void;
    onFieldChange?: (field: string, value: string | boolean) => void;
}

interface CustomLayoutFormSectionProps extends LayoutFormSectionProps {
    children?: React.ReactNode;
}

const CustomLayoutFormSection: React.FC<CustomLayoutFormSectionProps> = props => {
    const { layoutSection, children, entity, entityId, onFieldChange } = props;

    if (!layoutSection) {
        return null;
    }

    return (
        <>
            {layoutSection.sectionKey ===
            LayoutSectionKey.CommunityDetailsCommunityInfo ? (
                <div className="input-form-block">
                    <Header iconName={layoutSection.icon}>{layoutSection.title}</Header>
                    <CommunityDetailsFields
                        community={entity as ICommunity}
                        onFieldChange={onFieldChange}
                    />
                    {children}
                </div>
            ) : null}
            {layoutSection.sectionKey ===
            LayoutSectionKey.CommunityDetailsCommunityContacts ? (
                <CommunityContacts communityId={entityId as CommunityId} />
            ) : null}
            {layoutSection.sectionKey === LayoutSectionKey.CommunityDetailsPhotos ? (
                <EntityPhotos
                    entityId={entityId}
                    icon="55_photos"
                    heading="Community Photos"
                />
            ) : null}
        </>
    );
};

export const LayoutFormSection: React.FC<LayoutFormSectionProps> = props => {
    const {
        entityType,
        mode,
        entity,
        entityId,
        answerMode,
        onAnswerCommitted,
        layoutSection,
    } = props;

    const entitiesQuery = useGetEntitiesQuery({}, stableQueryOptions());

    if (!layoutSection) {
        return null;
    }

    return (
        <QueryRenderer
            query={entitiesQuery}
            name="LayoutFormSection.entitiesQuery"
            loadingComponent={null}>
            {data => {
                const entityMetadata = data.getEntities.find(e => e.name === entityType);
                if (!entityMetadata) {
                    console.warn(`Unexpected: Entity ${entityType} not found`);
                    return null;
                }

                switch (layoutSection.renderBehavior) {
                    case SectionRenderBehaviorType.Notes:
                        return <Notes entityId={entityId} />;
                    case SectionRenderBehaviorType.Custom:
                        return <CustomLayoutFormSection {...props} />;
                    case SectionRenderBehaviorType.CustomWithLayoutItems:
                        return (
                            <CustomLayoutFormSection {...props}>
                                <EntityLayoutItemsFormSection
                                    entityId={entityId}
                                    entityMetadata={entityMetadata}
                                    section={layoutSection}
                                    mode="fieldsonly"
                                    entity={entity}
                                    answerMode={answerMode}
                                    onAnswerCommitted={onAnswerCommitted}
                                />
                            </CustomLayoutFormSection>
                        );
                    case SectionRenderBehaviorType.LayoutItems:
                        return (
                            <EntityLayoutItemsFormSection
                                entityId={entityId}
                                entityMetadata={entityMetadata}
                                section={layoutSection}
                                mode={mode}
                                entity={entity}
                                answerMode={answerMode}
                                onAnswerCommitted={onAnswerCommitted}
                            />
                        );
                    default:
                        return null;
                }
            }}
        </QueryRenderer>
    );
};
